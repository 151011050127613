import Image from 'next/image';
import { Disclosure } from '@headlessui/react';
import { type FC } from 'react';

import { Arrow } from 'assets/icons';
import { FooterLink } from 'components/FooterLink';

interface FooterConditionsProps {
  category: string;
  links: { label: string; href: string }[];
}

const FooterMenuItem: FC<FooterConditionsProps> = ({ links, category }) => (
  <Disclosure>
    {({ open }) => (
      <>
        <div>
          <Disclosure.Button className="flex items-center gap-x-3 text-sm font-medium lg:justify-normal lg:text-xs">
            {category}
            <Image alt="" className={open ? 'rotate-180' : 'rotate-0'} src={Arrow} />
          </Disclosure.Button>
        </div>
        <Disclosure.Panel as="div" className="flex flex-col gap-y-3 text-sm lg:text-xs">
          {links.slice(0, 3).map((link, index) => (
            <FooterLink key={index} link={link} />
          ))}
          <a className="leading-4 text-gold-900 underline" href="/#conditions">
            See More &gt;
          </a>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default FooterMenuItem;
