'use client';
import type { ReactElement } from 'react';

import { Disclosure } from '@headlessui/react';
import Image from 'next/image';

import { Arrow } from 'assets/icons';
import { Separator } from 'components/Separator';
import { isCostPlusPageFeatureFlagEnabled, isNewsFeatureFlagEnabled } from 'env';
import useViewport from 'hooks/useViewport';
import { FooterMenuItem } from 'components/FooterMenuItem';
import { FooterLink } from 'components/FooterLink';

const footerMenuOptions = [
  {
    label: 'Conditions',
    innerOptions: true,
  },
  {
    label: 'Medications',
    options: [
      ...(isCostPlusPageFeatureFlagEnabled
        ? [
            {
              label: 'Cost Plus Drugs',
              href: 'cost-plus',
            },
          ]
        : []),
      {
        label: 'Short-term Prescription Refills',
        href: 'short-term-prescription-refills',
      },
    ],
  },
  {
    label: 'Resources',
    options: [
      { label: 'Blog', href: 'blog' },
      ...(isNewsFeatureFlagEnabled ? [{ label: 'News', href: 'news' }] : []),
    ],
  },
];

interface FooterConditionsProps {
  conditionsLinks: Record<string, { label: string; href: string }[]>;
}
interface FooterMenuComponentsProps {
  label: string;
  footerOptions: ReactElement[] | undefined;
}

function FooterMenuDisclosure({ label, footerOptions }: FooterMenuComponentsProps) {
  return (
    <Disclosure as="div" className="flex flex-col py-8">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-center justify-between font-semibold text-green-800">
            {label}
            <Image alt="" className={open ? 'rotate-180' : 'rotate-0'} src={Arrow} />
          </Disclosure.Button>
          <Disclosure.Panel as="div" className="flex flex-col gap-y-4 pt-4">
            {footerOptions}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

function FooterMenuColumn({ label, footerOptions }: FooterMenuComponentsProps) {
  return (
    <div className="w-40">
      <span className="text-sm font-semibold text-green-800">{label}</span>
      <div className="flex flex-col gap-y-4 pt-4">{footerOptions}</div>
    </div>
  );
}

export default function FooterMenu({ conditionsLinks }: FooterConditionsProps) {
  const { isMobile, isTablet, isDesktop } = useViewport();

  return (
    <>
      {footerMenuOptions.map(({ label, innerOptions, options }) => {
        const footerOptions = innerOptions
          ? Object.keys(conditionsLinks).map((category) => (
              <FooterMenuItem
                key={category}
                category={category}
                links={conditionsLinks[category]}
              />
            ))
          : options?.map((option, index) => <FooterLink key={index} link={option} />);

        return isMobile || isTablet || isDesktop ? (
          <div key={label}>
            <FooterMenuDisclosure footerOptions={footerOptions} label={label} />
            <Separator />
          </div>
        ) : (
          <FooterMenuColumn key={label} footerOptions={footerOptions} label={label} />
        );
      })}
    </>
  );
}
