import DesktopMenuItem, { type MenuItemProps } from 'components/MenuItem/DesktopMenuItem';

interface DesktopMenuProps {
  menuHeaderLinks: MenuItemProps[];
}

const DesktopMenu = ({ menuHeaderLinks }: DesktopMenuProps) => (
  <ul
    className={
      'relative left-0 z-10 hidden self-center bg-white duration-200 ease-in peer-[.menu-checkbox]:peer-checked:translate-x-0 sm:flex'
    }
  >
    {menuHeaderLinks.map((menuLink, idx) => (
      <li key={idx} className="self-center px-5 py-3 sm:px-4 sm:py-0">
        <DesktopMenuItem {...menuLink} />
      </li>
    ))}
  </ul>
);

export default DesktopMenu;
