'use client';

import { type Dispatch, type FC, type SetStateAction, useEffect, useContext } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

import { type MenuItemProps } from 'components/MenuItem/DesktopMenuItem';
import { useDesktopMenuItem } from 'components/MenuItem/hooks/useDesktopMenuItem';
import { Arrow } from 'assets/icons';
import { HeaderContext } from 'components/Header/context/headerContext';

interface MobileMenuItemProps extends MenuItemProps {
  setShowMenu: Dispatch<SetStateAction<boolean>>;
  showGoBack: boolean;
  setShowGoBack: Dispatch<SetStateAction<boolean>>;
}

const MobileMenuItem: FC<MobileMenuItemProps> = (props: MobileMenuItemProps) => {
  const { subMenu, label, path, setShowGoBack, showGoBack, setShowMenu } = props;
  const { handleHideMenu } = useContext(HeaderContext);

  const router = useRouter();

  const {
    hasNestedSubmenu,
    hideMenu,
    showMenu,
    showSubMenu,
    handleHideNestedSubMenu,
    showNestedSubMenu,
    handleShowNestedSubMenu,
    nestedSubmenu,
  } = useDesktopMenuItem(props);

  useEffect(() => {
    setShowGoBack(showNestedSubMenu);
  }, [showNestedSubMenu, setShowGoBack]);

  useEffect(() => {
    if (!showGoBack) handleHideNestedSubMenu();
  }, [showGoBack, handleHideNestedSubMenu]);

  return (
    <div className={`flex flex-col ${showSubMenu ? 'bg-green-50' : ''}`}>
      {showNestedSubMenu ? (
        <div className="group/subMenuItemLinks inline-flex grow flex-col bg-green-50 px-4 py-4">
          <span className="pb-3 text-xs text-black-700">{nestedSubmenu.label}</span>
          <ul className="flex flex-col overflow-y-scroll">
            {nestedSubmenu.subMenu.map(({ label, path }) => (
              <li
                key={path}
                className="group/subMenuItemLink py-3 text-black-900 hover:font-semibold hover:text-green-900"
                onClick={handleHideMenu}
              >
                <Link className="text-sm" href={`${path ? `/${path}` : ''}`}>
                  <span className="group-hover/subMenuItemLink:underline">{label}</span>
                  <span> &gt;</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className={`flex items-center justify-between px-5 font-semibold text-black-900 ${
            path ? 'cursor-pointer' : 'cursor-default'
          } ${showSubMenu ? 'pt-4' : ''}`}
          onClick={() => {
            if (!showSubMenu) showMenu();
            else hideMenu();
          }}
        >
          <span>{label}</span>
          <Image
            alt={showSubMenu ? 'Expand sub menu' : 'Collapse sub menu'}
            className={`
              ${
                showSubMenu ? '-rotate-180' : ''
              } h-[9px] w-[16.5px] transition-all duration-300 ease-in-out`}
            src={Arrow}
          />
        </div>
      )}
      {showSubMenu && !showNestedSubMenu && (
        <ul className="mt-3 flex flex-col">
          {subMenu.map((item, idx) => (
            <li
              key={idx}
              className="px-5 py-2 text-black-900 hover:font-semibold"
              onClick={() => {
                if (hasNestedSubmenu) handleShowNestedSubMenu(item);
                else {
                  setShowMenu(false);
                  router.push(item.path ?? '');
                  handleHideMenu();
                }
              }}
            >
              <p>
                <span> {item.label}</span>
                <span> &gt;</span>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MobileMenuItem;
