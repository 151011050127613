import { useEffect, useState } from 'react';

type WindowSizeTypes = number | boolean | undefined;

type WindowValuesType = number | undefined;

interface ViewportData {
  width: WindowValuesType;
  height: WindowValuesType;
  isMobile: WindowSizeTypes;
  isTablet: WindowSizeTypes;
  isDesktop: WindowSizeTypes;
  isLargeDesktop: WindowSizeTypes;
}

const MOBILE_BREAKPOINT = 480;
const TABLET_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1024;
const LARGE_DESKTOP_BREAKPOINT = 1440;

const useViewport = (): ViewportData => {
  const [width, setWidth] = useState<WindowValuesType>(undefined);
  const [height, setHeight] = useState<WindowValuesType>(undefined);

  const isMobile = width && width < MOBILE_BREAKPOINT;
  const isTablet = width && width >= MOBILE_BREAKPOINT && width < TABLET_BREAKPOINT;
  const isDesktop = width && width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT;
  const isLargeDesktop = width && width >= DESKTOP_BREAKPOINT && width < LARGE_DESKTOP_BREAKPOINT;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { width, height, isMobile, isTablet, isDesktop, isLargeDesktop };
};

export default useViewport;
