'use client';

import Link from 'next/link';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import SubMenu from 'components/SubMenu/SubMenu';

import { useDesktopMenuItem } from './hooks/useDesktopMenuItem';

interface WrapperComponentProps {
  children: React.ReactNode;
  className: string;
  href: string;
  style: React.CSSProperties;
  Wrapper: typeof Link | 'span';
}

const WrapperComponent = ({
  children,
  className,
  Wrapper = Link,
  href,
  style,
}: WrapperComponentProps) => {
  if (Wrapper === 'span') {
    return <span className={className}>{children}</span>;
  }

  return (
    <Link className={className} href={href} style={style}>
      {children}
    </Link>
  );
};

export interface SubMenuItemProps {
  noLink?: boolean;
  label: string;
  path?: string;
  subMenu: SubMenuItemProps[];
}

export interface MenuItemProps {
  noLink?: boolean;
  label: string;
  path?: string;
  passedHref?: string;
  subMenu: SubMenuItemProps[];
}

const MenuItem = (props: MenuItemProps) => {
  const { noLink = false, path, label } = props;

  const { hasNestedSubmenu, hideMenu, showMenu, showSubMenu, url } = useDesktopMenuItem(props);

  return (
    <Popover className={'relative'} onMouseLeave={hideMenu}>
      {() => (
        <>
          <WrapperComponent
            Wrapper={noLink ? 'span' : Link}
            className={`relative py-2 text-sm font-medium hover:text-green-800 md:font-light`}
            href={path ? url : ''}
            style={{ cursor: path ? 'pointer' : 'default' }}
          >
            <>
              <Popover.Button
                onMouseEnter={() => {
                  showMenu();
                }}
              >
                {label}
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                show={showSubMenu}
              >
                <div className="absolute left-[25%] top-[70%] z-[999] h-[0] w-[0] border-[20px] border-t-[0px] border-white border-l-transparent border-r-transparent" />
              </Transition>
            </>
          </WrapperComponent>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            show={showSubMenu}
          >
            <Popover.Panel
              className={`absolute z-10 ${
                !hasNestedSubmenu ? '300px' : 'w-screen'
              } max-w-[450px] translate-x-[-10%] transform px-4 pt-2 sm:translate-x-[-10%] sm:px-0 md:translate-x-[-20%] lg:max-w-2xl lg:translate-x-[-8%]`}
            >
              <SubMenu {...props} />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default MenuItem;
