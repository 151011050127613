import { createContext } from 'react';

interface HeaderContextValue {
  showSubMenu: boolean;
  showMenu: boolean;
  handleShowMenu: () => void;
  handleHideMenu: () => void;
  handleHideSubMenu: () => void;
  handleShowSubMenu: () => void;
}

export const HeaderContext = createContext<HeaderContextValue>({
  showSubMenu: false,
  showMenu: false,
  handleHideMenu: () => ({}),
  handleShowMenu: () => ({}),
  handleHideSubMenu: () => ({}),
  handleShowSubMenu: () => ({}),
});
