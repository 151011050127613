import { type Dispatch, useEffect, useReducer } from 'react';

import { type NestedSubmenu } from 'components/NestedSubMenu/NestedSubMenu';

import { type SubMenuProps } from '../SubMenu';

interface State {
  hasNestedSubmenu: boolean;
  nestedSubmenu: NestedSubmenu;
  activeSubmenu: string;
}

interface UseSubMenuState extends State {
  dispatch: Dispatch<Partial<State>>;
}

export const useSubMenuState = ({ subMenu }: SubMenuProps): UseSubMenuState => {
  const [state, dispatch] = useReducer(
    (state: State, newState: Partial<State>) => {
      if (newState.activeSubmenu) {
        if (newState.activeSubmenu === state.activeSubmenu)
          return { ...state, ...newState, activeSubmenu: state.activeSubmenu };
      }

      return { ...state, ...newState };
    },
    {
      hasNestedSubmenu: false,
      nestedSubmenu: { title: '', submenu: [] },
      activeSubmenu: '',
    },
  );

  useEffect(() => {
    if (!subMenu) return;

    const nestedSubmenuFiltered = subMenu.filter(({ subMenu }) => subMenu && subMenu.length > 0);

    dispatch({ hasNestedSubmenu: nestedSubmenuFiltered.length > 0 });

    if (nestedSubmenuFiltered.length > 0)
      dispatch({
        nestedSubmenu: {
          title: nestedSubmenuFiltered[0].label,
          submenu: nestedSubmenuFiltered[0].subMenu ?? [],
        },
        activeSubmenu: '',
      });
  }, [subMenu]);

  return { ...state, dispatch };
};
