import Link from 'next/link';
import { type FC } from 'react';

interface FooterLinkProps {
  link: { label: string; href: string };
}

const FooterLink: FC<FooterLinkProps> = ({ link }) => (
  <div className="text-sm font-thin lg:text-xs">
    <Link href={`/${link.href}`}>
      <span className="font-light">{link.label}</span>
    </Link>
  </div>
);

export default FooterLink;
