import { useCallback, useContext, useEffect, useReducer } from 'react';

import { HeaderContext } from 'components/Header/context/headerContext';

import { type SubMenuItemProps, type MenuItemProps } from '../DesktopMenuItem';

interface State {
  showSubMenu: boolean;
  showNestedSubMenu: boolean;
  hasNestedSubmenu: boolean;
  nestedSubmenu: SubMenuItemProps;
  url: string;
}

interface IDesktopMenuItemState extends State {
  hideMenu: () => void;
  showMenu: () => void;
  handleShowNestedSubMenu: (nestedSubmenu: SubMenuItemProps) => void;
  handleHideNestedSubMenu: () => void;
}

export const useDesktopMenuItem = ({
  passedHref,
  path,
  subMenu,
}: MenuItemProps): IDesktopMenuItemState => {
  const context = useContext(HeaderContext);
  const [state, dispatch] = useReducer(
    (state: State, newState: Partial<State>) => {
      return { ...state, ...newState };
    },
    {
      hasNestedSubmenu: false,
      showSubMenu: false,
      showNestedSubMenu: false,
      nestedSubmenu: { label: '', path: '', subMenu: [] },
      url: '',
    },
  );

  const hideMenu = () => {
    dispatch({ showSubMenu: false });
  };

  const showMenu = () => {
    dispatch({ showSubMenu: true });
  };

  const handleShowNestedSubMenu = (nestedSubmenu: SubMenuItemProps) => {
    dispatch({ showNestedSubMenu: true, nestedSubmenu });
  };

  const handleHideNestedSubMenu = useCallback(() => {
    dispatch({ showNestedSubMenu: false, nestedSubmenu: { label: '', path: '', subMenu: [] } });
  }, [dispatch]);

  useEffect(() => {
    if (!subMenu) return;

    const nestedSubmenuFiltered = subMenu.filter(({ subMenu }) => subMenu && subMenu.length > 0);

    dispatch({ hasNestedSubmenu: nestedSubmenuFiltered.length > 0 });
  }, [subMenu]);

  useEffect(() => {
    const url = `${path ?? ''}${passedHref ? `/?div=${passedHref}` : ''}`;

    dispatch({ url });
  }, [passedHref, path]);

  useEffect(() => {
    dispatch({ showSubMenu: false });
  }, [context.handleHideSubMenu]);

  return { ...state, showMenu, hideMenu, handleShowNestedSubMenu, handleHideNestedSubMenu };
};
