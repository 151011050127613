interface SeparatorProps {
  backgroundColor?: string;
  width?: string;
}

const Separator = ({ backgroundColor, width }: SeparatorProps) => (
  <div
    className={`m-auto block h-[1px] ${width ?? 'w-full'} ${backgroundColor ?? 'bg-black-400'}`}
  />
);

export default Separator;
