import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"fallback\":[\"sans-serif\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/agile-telehealth-logo.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/agile-telehealth-mobile-logo.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/arrow.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/at-pc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/chat-circle-text.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/check.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/circled-arrow.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/close.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/dw-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/email_1.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/envelope.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/facebook-footer.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/fm-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/green-grass-hex.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/green-hex.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hamburger-icon-menu.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-addiction-therapy.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-all-week.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-chronic-conditions.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-diet-and-weight.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-family-medicine.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-mens-health.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-mental-healthcare.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-pediatric-care.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-prescription.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-primary-care.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-sexual-health.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-skin-hair-care.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-urgent-care.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/hex-womens-health.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/instagram-footer.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/laptop.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/licensed_providers.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/linkedin-footer.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/logo-icon.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/map-pin.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/mh-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/mhc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/paper_pen_1.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/paper_pen_cp.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/pc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/phone-plus-icon.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/prescibe_rx_1.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/time.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/uc-cc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/user-icon.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/video-camera.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/wh-plant.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/yellow-banana-hex.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/assets/icons/yellow-hex.svg");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/components/Footer/FooterMenu.tsx");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/components/Navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/Users/curtisolson/Projects/agiletelehealth-website/src/styles/globals.css")